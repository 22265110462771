@import 'mixins';

.search--dashboard, .header {
  background: #061826 !important;
}

.nav {
  background: #0f253c;
}

.navUnder {
  background: #19476b;
}

.navUnder__item {
  color: #fff;
}

.navUnder__item.s--on, .navUnder__item:hover {
  color: #f46d2b;
}

.secondarynav__item.s--on {
  color: #fff;
}

.secondarynav__item.s--on a {
  background: #0f253c;
}

.env--live .envlabel, .env--live .nav__item.s--on a:after,
.env--prod .envlabel, .env--prod .nav__item.s--on a:after,
.env--production .envlabel, .env--production .nav__item.s--on a:after {
  background: #f46d2b;
}

.listing__nav {
  background: #255f8d;
}

.secondarynav__item a, .button--ghost {
  //color: #fff !important;
}

.tablehead__cell {
  color: #245e8c !important;
}

.datatable__table {
  border-color: #245e8c !important;
  .tablerow {
    border-color: #245e8c !important;
  }
}

/* Manag Summary */
.manage-summary {
  margin-top: 20px;
  .summary-block {
    padding: 0 0 10px 0;
  }
  h2 {
    font-size: 22px;
    font-weight: bold;
    padding: 20px 0;
  }
  h4 {
    position: relative;
    padding-left: 24px;
    font-weight: bold;

    &:before {
      position: absolute;
      left: 0;
      top: 1px;
      content: "";
      margin: 2px 10px 0 5px;
      display: inline-block;
      width: 13px;
      height: 13px;
      border: 1px solid #959595;
      border-radius: 50%;
      z-index: 2;
    }
    &.completed_0 {
      &:before {
        border-color: #FA0A0A;
        background-color: #FA0A0A;
      }
    }
    &.completed_1 {
      &:before {
        content: "";
        width: 5px;
        left: 5px;
        background: transparent;
        border: solid #1fdd3e;
        border-width: 0 2px 2px 0;
        border-radius: 0;
        transform: rotateZ(45deg);
      }
    }
  }
  .manage-download {
    padding-left: 24px;
  }
  .manage-download-green {
    background: #39B71A;
    border-color: #39B71A;
    &:hover, &:active, &:focus {
      background: #2f9815 !important;
      border-color: #2f9815 !important;
    }
  }
}



.summary-table {
  width: 100%;
  border-bottom: 1px solid #28557e;
  tr {
    background-color: #e1e1e1;
    border: 1px solid #28557e;
    border-bottom: none;
  }
  tr:nth-child(even) {
    background-color: #ededed;
  }
  td {
    padding: 15px 10px 15px 15px;
    color: #28557e;
    a {
      padding: 6px 14px !important;
      background: #133654;
      border-radius: 8px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      text-align: center;
      transition: background 0.3s;
      &:hover{
        background: #28557e;
      }
    }
  }
}